import ClipboardJS from 'clipboard'

var clipboards = new ClipboardJS('.copy')

clipboards.on('success', function (event) {
  event.clearSelection()
  var text = event.trigger.textContent
  event.trigger.textContent = 'Copied'
  window.setTimeout(function () {
    event.trigger.textContent = text
  }, 2000)
})

clipboards.on('error', function (event) {
  var text = event.trigger.textContent
  event.trigger.textContent = 'Press "Ctrl + C" to copy'
  window.setTimeout(function () {
    event.trigger.textContent = text
  }, 2000)
})

var trigger = document.getElementById('email-trigger')

if (trigger) {
  var email_wrapper = document.getElementById('email-content-wrapper')
  var inner_height = email_wrapper.scrollHeight
  var email = document.getElementById('id_email')

  window.setInterval(function () {
    var height = email_wrapper.offsetHeight
    var speed = 10

    if (trigger.checked && height < inner_height) height += speed
    else if (!trigger.checked && height > 0) height -= speed

    if (height > inner_height) height = inner_height
    else if (height < speed) height = 0

    if (height === inner_height) email_wrapper.style.overflow = 'visible'
    else email_wrapper.style.overflow = 'hidden'

    if (email.value != '' && !trigger.checked) email.value = ''

    if (speed != 0) email_wrapper.style.height = height + 'px'
  }, 1000 / 60)
}
